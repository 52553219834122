var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.fieldsLoading
        ? _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            _vm._l(9, function (n) {
              return _c(
                "v-col",
                { key: n, attrs: { cols: "12", sm: "6", lg: "3" } },
                [
                  _c(
                    "v-sheet",
                    { staticClass: "mt-2" },
                    [
                      _c("v-skeleton-loader", {
                        staticClass: "mx-auto",
                        attrs: { type: "list-item" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          )
        : [
            _c(
              "v-row",
              { staticClass: "mt-3 mb-2 mx-0" },
              [
                _c(
                  "v-col",
                  { staticClass: "pa-0 pl-1 pt-1", attrs: { cols: "12" } },
                  [
                    _c("v-autocomplete", {
                      attrs: {
                        outlined: "",
                        dense: "",
                        label: _vm.$t("message.projects.project"),
                        items: _vm.listOfProjects,
                        "hide-details": "",
                        "item-text": "projectName",
                        "item-value": "id",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.setActiveProject(_vm.activeProjectId)
                        },
                      },
                      model: {
                        value: _vm.activeProjectId,
                        callback: function ($$v) {
                          _vm.activeProjectId = $$v
                        },
                        expression: "activeProjectId",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "v-row",
              { staticClass: "mb-2 mx-0" },
              [
                _c("form-data", {
                  ref: "projectFormRef",
                  attrs: {
                    references: _vm.formReferences,
                    model: _vm.projectObj,
                  },
                  on: {
                    "update:references": function ($event) {
                      _vm.formReferences = $event
                    },
                  },
                }),
              ],
              1
            ),
            [
              _c(
                "v-row",
                _vm._l(_vm.speedDialItems, function (item, index) {
                  return _c(
                    "v-col",
                    {
                      key: index,
                      staticClass: "pa-1",
                      attrs: {
                        cols: "6",
                        lg: "2",
                        md: "3",
                        sm: "4",
                        xl: "2",
                        xs: "2",
                      },
                    },
                    [
                      _c(
                        "v-card",
                        {
                          key: index,
                          attrs: {
                            to: item.to,
                            outlined: "",
                            rounded: "lg",
                            border: "",
                            variant: "outlined",
                          },
                        },
                        [
                          _c(
                            "v-card-text",
                            { staticClass: "text-center" },
                            [
                              item.type === "link"
                                ? [
                                    _c(
                                      "a",
                                      {
                                        attrs: {
                                          href: item.link,
                                          target: "_blank",
                                        },
                                      },
                                      [_vm._v(_vm._s(item.name))]
                                    ),
                                  ]
                                : [_vm._v(_vm._s(item.name))],
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }